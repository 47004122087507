<template>
  <div class="w-full max-w-4xl py-20 mb-200">
    <Spinner v-if="healthActionsLoading" />
    <div v-if="healthAction">
      <Back
        route="/"
        title="health_actions.details.back_to_home"
        class="my-20"
      />
      <h1 class="font-bold mb-25 h3">{{ title }}</h1>
      <div class="flex flex-wrap">
        <ProgressCard />
        <div class="flex-1">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Spinner from '@components/common/Spinner'
import ProgressCard from './ProgressCard'
import healthActionMixin from '@platform-shared/mixins/health-actions/healthActionMixin.js'

export default {
  components: {
    Spinner,
    ProgressCard,
  },
  mixins: [healthActionMixin],
}
</script>
