<template>
  <Card
    icon="Person"
    :title="$t('health_actions.details.assigned_to', [assignedNames])"
    class="mb-30"
    accentColor="sky"
  />
</template>

<script>
import { Card } from '@components/common/card/index'
import healthActionMixin from '@platform-shared/mixins/health-actions/healthActionMixin.js'

export default {
  components: { Card },
  mixins: [healthActionMixin],
}
</script>

<style></style>
