<template>
    <div class="flex justify-center">
        <Body class="px-5 font-bold bg-grey-200">
            {{ text }}
        </Body>
    </div>
</template>

<script>

export default {
  props: {
    text: {
      type: String,
      default: null,
    },
  },
}
</script>

<style></style>