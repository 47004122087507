<template>
  <div>
    <Spinner v-if="isLoading" />
    <div v-else-if="!yearlyCompletions.length">
      {{ $t('questionnaire.mock_cahps_results.no_results') }}
    </div>
    <div v-else>
      <h2>
        {{ $t('questionnaire.mock_cahps_results.title') }}
      </h2>
      <div class="border border-grey-400 px-30 py-20 my-12 rounded-lg">
        <p>
          {{
            $t('questionnaire.mock_cahps_results.completed_on', [completedOn])
          }}
        </p>
      </div>
      <div>
        <div
          v-for="(question, qIdx) in questions"
          :key="question.question"
          class="flex items-center p-20"
          :class="{ 'bg-grey-100': qIdx % 2 === 1 }"
        >
          <p class="flex-1 font-semibold mr-20">{{ question.question }}</p>
          <p class="mt-5 w-200">
            {{ question.answers.join(', ') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mockCahpsResults from '@platform-shared/mixins/questionnaire/mockCahpsResults'
import Spinner from '@components/common/Spinner'
import { QUESTIONNAIRE_TYPES } from '@platform-shared/constants'

export default {
  components: { Spinner },
  mixins: [mockCahpsResults],
  mounted() {
    !this.mockCahps &&
      this.getQuestionnairesByType(QUESTIONNAIRE_TYPES.MOCK_CAHPS)
  },
}
</script>

<style></style>
