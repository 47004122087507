<template>
  <SurveyForm :config="formattedAttestationConfig" @onSubmit="handleSubmit" />
</template>

<script>
import _omit from 'lodash/omit'
import attestationFormMixin from '@platform-shared/mixins/health-actions/attestationFormMixin.js'
import SurveyForm from './SurveyForm.vue'

export default {
  components: {
    SurveyForm,
  },
  mixins: [attestationFormMixin],
}
</script>
