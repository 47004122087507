<template>
  <Card
    :title="$t('health_actions.details.to_do')"
    icon="Checklist"
    accentColor="sky"
    :subtitle="toDoSubtitle"
  >
    <Spinner v-if="learnInitLoading" />
    <div v-else>
      <div
        v-for="(item, i) in articleList"
        :key="item"
        class="flex items-center justify-center p-20 border-t first:border-t-0"
      >
        <div
          v-if="i === historicalArticleCount"
          class="flex flex-col items-center"
        >
          <div
            class="flex items-center justify-center rounded-full w-60 h-60 text-sky-500 bg-sky-100"
          >
            <BookOpen class="h-35 w-35" />
          </div>
          <HMButton
            :to="{
              name: 'learn-articles',
            }"
            rounded="full"
            :title="$t('common.privacy')"
            class="mt-10 w-300"
          >
            Read Your {{ item }} Article
          </HMButton>
        </div>
        <div
          v-else-if="i > historicalArticleCount"
          class="flex flex-col items-center"
        >
          <BookOpen class="h-20" />
          <span class="font-bold">Read Your {{ item }} Article</span>
        </div>
        <div v-else class="flex flex-col items-center">
          <CheckCircle class="text-green-500 h-25" />
          <span class="font-bold">{{ historicalArticles[i].title }}</span>
          <span
            v-text="
              `Completed ${formatDate(historicalArticles[i].completionDate)}`
            "
          />
        </div>
      </div>
    </div>
  </Card>
</template>

<script>
import Spinner from '../../../common/Spinner'
import { Card } from '@components/common/card/index'
import { BookOpen, CheckCircle } from '@assets/svgs/index'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import healthActionMixin from '@platform-shared/mixins/health-actions/healthActionMixin.js'

export default {
  components: { Spinner, Card, BookOpen, CheckCircle },
  mixins: [healthActionMixin],
  data() {
    return {
      articleList: ['first', 'second', 'third', 'fourth', 'fifth', 'sixth'],
    }
  },
  methods: {
    formatDate(date) {
      return date ? format(parseISO(date), 'MM/dd/yyyy') : ''
    },
  },
}
</script>
