<template>
  <div class="lg:flex space-x-20 items-stretch">
    <Card class="p-25 flex-1">
      <div class="flex space-x-30">
        <div class="flex-1 flex flex-col justify-center">
          <CurrentBalanceHeader v-if="usesRewardRedemption" />
          <EarnedProgressBar />
        </div>

        <div class="flex flex-col justify-center space-y-8">
          <HMButton @click="$router.push('/rewards/redeem')">
            {{
              canAffordRedemption
                ? $t('rewards.landing.redeem_now')
                : $t('rewards.landing.view_rewards')
            }}
          </HMButton>
          <HMButton
            v-if="usesPoints"
            :to="{
              name: 'rewards',
              hash: '#how-it-works',
            }"
            color="white"
            outlined
          >
            {{ $t('login.learn_more.link_title') }}
          </HMButton>
        </div>
      </div>
    </Card>
    <FamilyRewardsCard v-if="showFamilyRewards" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import EarnedProgressBar from '../rewards/EarnedProgressBar'
import CurrentBalanceHeader from '../rewards/CurrentBalanceHeader'
import FamilyRewardsCard from '../rewards/FamilyRewardsCard'
import { Card } from '@components/common/card/index'

export default {
  components: {
    Card,
    EarnedProgressBar,
    CurrentBalanceHeader,
    FamilyRewardsCard,
  },
  computed: {
    ...mapGetters('rewards', [
      'showFamilyRewards',
      'usesRewardRedemption',
      'usesPoints',
      'canAffordRedemption',
    ]),
  },
}
</script>
