<template>
  <keep-alive>
    <router-view></router-view>
  </keep-alive>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  created() {
    this.initHealthActions()
  },
  methods: {
    ...mapActions('healthActions', ['initHealthActions']),
  },
}
</script>
