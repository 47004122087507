<template>
  <div class="flex flex-col justify-center p-50 pb-40 w-350 md:w-435 relative">
    <div class="absolute right-0 top-0 pt-20 pr-20">
      <CloseIcon @click="$emit('close')" />
    </div>
    <div class="text-center px-5">
      <p class="text-2xl">
        <span class="font-semibold">{{ $t('hra.start_pop_up.title') }}</span> 👋
      </p>
      <p class="text-center mt-20">
        <img
          src="@assets/images/hra/clipboard-pencil.png"
          class="inline-block"
          :alt="$t('hra.start_pop_up.start')"
        />
      </p>
      <p class="mt-30">
        {{ $t('hra.start_pop_up.time_to_spare') }}<br />
        {{ $t('hra.start_pop_up.fill_out') }}
      </p>
      <p class="mt-20">
        {{ $t('hra.details.insight') }}
      </p>
    </div>
    <HMButton
      size="large"
      class="mt-40"
      rounded="full"
      fontWeight="semibold"
      to="/questionnaire/HRA"
    >
      {{ $t('hra.start_pop_up.start') }}
    </HMButton>
    <HMButton
      color="transparent"
      rounded="full"
      fontWeight="semibold"
      class="p-15 font-semibold mt-10"
      @click="$emit('close')"
    >
      {{ $t('hra.start_pop_up.later') }}
    </HMButton>
  </div>
</template>

<script>
import CloseIcon from '@components/common/CloseIcon'
import getTime from 'date-fns/getTime'

export default {
  components: { CloseIcon },
  created() {
    localStorage.setItem('hraModalViewTime', getTime(new Date()))
  },
}
</script>
