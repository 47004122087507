<template>
  <div>
    <ToDoNotesIconSection />
    <div class="px-80 mt-20">
      <Body class="text-center">
        <span class="block font-bold">{{$t('health_actions.learn_and_earn.read_article')}}</span>
        <span class="block text-sm">{{ $t('health_actions.learn_and_earn.description')}}</span>
      </Body>
      <div class="px-80 mt-20">
        <PendingBadge v-if="displayPending" :text="$t('health_actions.learn_and_earn.pending')" />
        <HMButton v-else rounded="full" :to="{ name: 'learn-and-earn-core', params: { id: this.$route.params.id, contentId: this.contentId, url: this.url } }">
          {{ $t('health_actions.learn_and_earn.start') }}
        </HMButton>
      </div>
    </div>
  </div>
</template>

<script>
import memberActivitySubmissionMixin from '@platform-shared/mixins/health-actions/memberActivitySubmissionMixin.js'
import ToDoNotesIconSection from './ToDoNotesIconSection.vue'
import PendingBadge from './PendingBadge'

export default {
  components: {
    ToDoNotesIconSection,
    PendingBadge,
  },
  mixins: [memberActivitySubmissionMixin],
  computed: {
    contentId() {
      return this.healthAction.parameter.contentId
    },
    url() {
      return this.healthAction.parameter.url
    },
    displayPending() {
      return (
        this.hasMarkedArticleAsRead || this.memberMarkedArticleAsReadThisPeriod()
      )
    },
    
  },
}
</script>
