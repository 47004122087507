<template>
  <Card
    class="relative w-full mb-30"
    :title="$t('health_actions.details.overview')"
    icon="Clipboard"
    accentColor="sky"
  >
    <Markdown class="p-20 overflow-x-auto" :source="longDescription" />
  </Card>
</template>

<script>
import { Card } from '@components/common/card/index'
import Markdown from '@components/common/Markdown'
import healthActionMixin from '@platform-shared/mixins/health-actions/healthActionMixin.js'
export default {
  components: {
    Card,
    Markdown,
  },
  mixins: [healthActionMixin],
}
</script>

<style></style>
