<template>
  <div class="w-full">
    <Back
      route="/health-actions/hra"
      title="questionnaire.hra_results.back_to_details"
      class="my-20"
    />
    <Spinner v-if="isLoading" />
    <div v-else-if="!yearlyCompletions.length">
      {{ $t('questionnaire.hra_results.no_results') }}
    </div>
    <div v-else>
      <h2>
        {{ $t('questionnaire.hra_results.title') }}
      </h2>
      <div class="border border-grey-400 px-30 py-20 my-12 rounded-lg">
        <p v-if="yearlyCompletions.length === 1">
          {{
            $t('questionnaire.hra_results.completed_on', [selectedCompletion])
          }}
        </p>
        <div v-else class="flex items-center justify-between">
          <FormListPicker
            v-model="selectedCompletion"
            :data="completionsDropdown"
            name="completion-selector"
            class="flex-1 max-w-xs mr-20"
          />
          <HMButton rounded="full" @click="showDrawer = true">
            {{ $t('questionnaire.hra_results.compare_btn') }}
          </HMButton>
        </div>
      </div>
      <div>
        <div
          v-for="(question, qIdx) in questions"
          :key="question.id"
          class="flex items-center p-20"
          :class="{ 'bg-grey-100': qIdx % 2 === 1 }"
        >
          <p class="flex-1 font-semibold mr-20">{{ question.question }}</p>
          <p class="mt-5 w-200">
            {{ question.answers.join(', ') }}
          </p>
        </div>
      </div>
      <Drawer v-model="showDrawer">
        <HraCompareDrawer
          :questionnaire="hra"
          :questions="questions"
          @closeDrawer="showDrawer = false"
        />
      </Drawer>
    </div>
  </div>
</template>

<script>
import _map from 'lodash/map'
import _get from 'lodash/get'
import _findIndex from 'lodash/findIndex'
import hraResults from '@platform-shared/mixins/questionnaire/hraResults'
import FormListPicker from '@components/common/forms/FormListPicker'
import Drawer from '@components/common/Drawer'
import HraCompareDrawer from './HraCompareDrawer'
import Spinner from '@components/common/Spinner'
import { QUESTIONNAIRE_TYPES } from '@platform-shared/constants'

export default {
  components: { FormListPicker, Drawer, HraCompareDrawer, Spinner },
  mixins: [hraResults],
  mounted() {
    !this.hra && this.getQuestionnairesByType(QUESTIONNAIRE_TYPES.HRA)
    this.selectedCompletion = _get(this.completionDates, 0, null)
  },
  data() {
    return {
      selectedCompletion: null,
      showDrawer: false,
    }
  },
  watch: {
    completionDates(val) {
      this.selectedCompletion = _get(val, 0)
    },
  },
  computed: {
    completionsDropdown() {
      return _map(this.completionDates, (date) => ({
        value: date,
        display: `Completed ${date}`,
      }))
    },
    selectedCompletionIdx() {
      return _findIndex(
        this.completionDates,
        (date) => date === this.selectedCompletion
      )
    },
  },
}
</script>

<style></style>
