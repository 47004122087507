<template>
  <li
    class="w-full m-10 border rounded-lg lg:w-1/2-20 min-w-xs h-80 border-grey-300 group"
    :class="showDependents && completed ? 'h-100' : 'h-80'"
  >
    <router-link
      class="flex items-center w-full h-full px-20 rounded-lg"
      :to="{ name: 'health-actions-details', params: routeParams }"
    >
      <div
        class="flex items-center justify-center rounded-full w-35 h-35"
        :class="`bg-sky-100 text-sky-500`"
      >
        <Icon v-if="completed" iconClass="text-xl fas fa-check h-full" />
        <RadialProgressBar
          v-else-if="isCompoundHealthAction"
          class="transform rotate-180"
          :diameter="45"
          :strokeWidth="5"
          :innerStrokeWidth="5"
          startColor="#FF8A00"
          stopColor="#FFD057"
          innerStrokeColor="#E6E6E6"
          :completed-steps="percentageComplete"
          :total-steps="100"
        >
          <Icon
            iconClass="text-xl fas fa-clipboard-list h-full"
            class="transform rotate-180"
          />
        </RadialProgressBar>
        <Icon v-else iconClass="text-xl fas fa-clipboard-list h-full" />
      </div>
      <div class="flex-1 ml-12 truncate">
        <p class="font-bold truncate" :title="action.title">
          {{ action.title }}
        </p>
        <span
          v-if="showDependents"
          class="px-5 mr-4 truncate rounded-sm bg-grey-100"
          :title="memberNames"
        >
          {{ memberNames }}
        </span>
        <p
          v-if="completed && incentiveCatalogCd"
          class="inline-block px-6 mt-4 mb-4 font-semibold text-green-600 bg-lime-100 rounded-sm"
        >
          {{
            $t('health_actions.home.earned_direct_incentive', [incentiveAmount])
          }}
        </p>
        <p
          v-else-if="!completed && incentiveCatalogCd"
          class="inline-block px-6 mt-4 mb-4 font-semibold text-green-600 bg-lime-100 rounded-sm"
        >
          {{ $t('health_actions.home.direct_incentive', [incentiveAmount]) }}
        </p>
        <p
          v-else-if="completed && totalRewardsEarned"
          class="inline-block px-6 mt-4 mb-4 font-semibold text-green-600 bg-lime-100 rounded-sm"
        >
          {{
            $t(`health_actions.home.earned.${currencyType}`, [
              totalRewardsEarned,
            ])
          }}
        </p>
        <p
          v-else-if="
            incentiveAmount && !completed && showEarning && earnableAmount > 0
          "
          class="inline-block px-6 mt-4 mb-4 font-semibold text-green-600 bg-lime-100 rounded-sm"
        >
          {{
            hasComplexChildrenWithRewards && usesPoints
              ? `${$t('health_actions.home.earn_up_to.points', [
                  incentiveAmount,
                ])}`
              : `${$t(`health_actions.home.earn.${currencyType}`, [
                  earnableAmount,
                ])}`
          }}
        </p>
      </div>
      <span
        class="icon-link w-35 h-35 rounded-full flex justify-center items-center"
      >
        <Icon
          iconClass="fa-chevron-right fas"
          :title="$t('common.icons.screen_reader_names.chevron_right')"
          class="text-black group-hover:text-blue-500"
        />
      </span>
    </router-link>
  </li>
</template>

<script>
import { mapGetters } from 'vuex'
import flow from 'lodash/fp/flow'
import orderBy from 'lodash/fp/orderBy'
import head from 'lodash/fp/head'
import getOr from 'lodash/fp/getOr'
import map from 'lodash/fp/map'
import join from 'lodash/fp/join'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import { HEALTH_ACTION_CODES as healthActionCodes } from '@platform-shared/constants'
import healthActionMixin from '@platform-shared/mixins/health-actions/healthActionMixin.js'
import rewardsCurrency from '@platform-shared/mixins/rewardsCurrency'
import RadialProgressBar from 'vue-radial-progress'

export default {
  components: { RadialProgressBar },
  mixins: [healthActionMixin, rewardsCurrency],
  props: {
    action: {
      type: Object,
      required: true,
    },
    completed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('rewards', ['currentRewardsSummary']),
    ...mapGetters('member', ['showDependents']),
    lastCompletion() {
      return flow(
        getOr([], 'subscriberCompletions'),
        orderBy('completionDate', 'desc'),
        head,
        getOr('', 'completionDate')
      )(this.action)
    },
    healthAction() {
      return this.action
    },
    routeParams() {
      switch (this.action.code) {
        case healthActionCodes.COMPLETE_HEALTH_QUESTIONNAIRE:
          return { id: 'hra' }
        default:
          return { id: this.action.id }
      }
    },
    members() {
      return flow(
        getOr([], this.completed ? 'completedMembers' : 'toDoMembers')
      )(this.action)
    },
    memberNames() {
      const total = this.members.length
      return total > 3
        ? this.$t('health_actions.home.you_plus_others', [total - 1])
        : flow(
            map(({ name }) => this.$t(name)),
            join(' + ')
          )(this.members)
    },
  },
  methods: {
    format(date) {
      return date ? format(parseISO(date), 'M/dd/yyyy') : ''
    },
  },
}
</script>
<style>
.health-action-icon-holder {
  @apply flex items-center bg-green-500;
  @apply text-center text-white rounded-l-lg;
  @apply justify-center;
  min-width: 100px;
  max-width: 100px;

  @screen md {
    min-width: 120px;
    max-width: 120px;
  }
}
</style>
