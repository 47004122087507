<template>
  <div class="flex flex-col items-center">
    <h5 class="mt-8 mx-70 text-center">
      {{ $t(`welcome_modal.${prepareLanguagePath}.whats_included.title`) }}
    </h5>
    <div class="flex-col">
      <div class="flex items-center mt-25">
        <img
          v-if="!usesPoints"
          class="w-80"
          src="@assets/images/welcome/heart.png"
        />
        <img v-else class="w-80" src="@assets/images/welcome/library.png" />
        <p class="ml-20 flex-1">
          {{ $t(`welcome_modal.${prepareLanguagePath}.whats_included.body_1`) }}
        </p>
      </div>
      <div class="flex items-center mt-25">
        <img
          v-if="!usesPoints"
          class="w-80"
          src="@assets/images/welcome/rewards.png"
        />
        <img v-else class="w-80" src="@assets/images/welcome/heart.png" />
        <p class="ml-20 flex-1">
          {{ $t(`welcome_modal.${prepareLanguagePath}.whats_included.body_2`) }}
        </p>
      </div>
      <div class="flex items-center mt-25">
        <img
          v-if="!usesPoints"
          class="w-80"
          src="@assets/images/welcome/library.png"
        />
        <img v-else class="w-80" src="@assets/images/welcome/rewards.png" />
        <p class="ml-20 flex-1">
          {{ $t(`welcome_modal.${prepareLanguagePath}.whats_included.body_3`) }}
        </p>
      </div>
    </div>
    <HMButton
      v-if="isFinalScreen"
      class="mt-50 w-350"
      rounded="full"
      @click="$emit('done')"
    >
      {{ $t('common.get_started_btn') }}
    </HMButton>
    <HMButton v-else class="mt-50 w-350" rounded="full" @click="$emit('next')">
      {{ $t('common.next_btn') }}
    </HMButton>
  </div>
</template>

<script>
import rewardsCurrency from '@platform-shared/mixins/rewardsCurrency'

export default {
  mixins: [rewardsCurrency],
  props: {
    isFinalScreen: { type: Boolean, required: true },
  },
}
</script>

<style></style>
