<template>
  <div>
    <div class="flex items-center mb-14">
      <h5 class="flex-1">Trackers</h5>
      <router-link
        :to="{ name: 'tracking' }"
        class="flex items-center text-sm text-blue-500 underline font-semibold py-5 rounded-full"
      >
        <span>{{ $t('tracking.home.tracker_settings') }}</span>
      </router-link>
    </div>
    <div class="flex" role="region" :aria-label="$t('tracking.slideshow')">
      <button
        class="w-40 h-80 mr-30 flex flex-0 justify-center items-center outline-none focus:outline-none"
        @click="$refs.trackerCarousel.previous()"
      >
        <i class="icon-caret-left text-2xl"></i>
      </button>
      <div class="flex-1 h-80">
        <vueper-slides
          ref="trackerCarousel"
          :key="sliderKey"
          :bullets="false"
          :arrows="false"
          class="no-shadow"
          :visible-slides="4"
          :gap="1"
          :breakpoints="sliderBreakpoints"
          fixed-height="80px"
          :touchable="false"
          :infinite="true"
          :slide-multiple="true"
          @ready="addSliderBreakpoints"
        >
          <vueper-slide v-for="tracker in trackers" :key="tracker.type">
            <template v-slot:content>
              <router-link
                :to="{
                  name: 'tracking-detail',
                  params: {
                    name: tracker.type,
                    backTo: '/',
                  },
                }"
                class="rounded-lg border border-grey-300 h-80 flex flex-row px-10 items-center"
              >
                <div
                  class="rounded-full w-35 h-35 mr-10 flex items-center justify-center bg-plum-100"
                >
                  <Icon
                    :iconClass="getIcon(tracker.type).type"
                    class="text-lg text-plum-500"
                  />
                </div>
                <div class="flex flex-col">
                  <span class="text-lg font-bold"
                    >{{ tracker.todaysDisplayValue }}
                    {{ $t(`tracking.lingo.${tracker.type}.units`) }}</span
                  >
                  <span class="text-sm">{{
                    $t(`tracking.lingo.${tracker.type}.display`)
                  }}</span>
                </div>
              </router-link>
            </template>
          </vueper-slide>
        </vueper-slides>
      </div>
      <button
        class="w-40 h-80 ml-30 flex flex-0 justify-center items-center outline-none focus:outline-none"
        @click="$refs.trackerCarousel.next()"
      >
        <i class="icon-caret-right text-2xl"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import { mapActions, mapGetters } from 'vuex'
import trackingIcons from '@platform-shared/mixins/tracking/trackingIcons'

export default {
  components: {
    VueperSlides,
    VueperSlide,
  },
  data() {
    return {
      sliderBreakpoints: {},
      sliderKey: 'slider',
    }
  },
  computed: {
    ...mapGetters('tracking', ['trackers']),
  },
  created() {
    this.initTracking()
  },
  methods: {
    ...mapActions('tracking', ['initTracking']),
    addSliderBreakpoints() {
      window.setTimeout(() => {
        this.sliderBreakpoints = {
          1024: { visibleSlides: 3 },
          800: { visibleSlides: 2 },
          640: { visibleSlides: 1 },
        }
        this.sliderKey = 'with-breakpoints'
      }, 1500)
    },
    getIcon(type) {
      return trackingIcons[type]
    },
  },
}
</script>
