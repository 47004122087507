<template>
  <div>
    <AssignedToCard v-if="showDependents" />
    <HraRiskSummaryCard v-if="isHra && hasHraRisk && !!hraOverallRisk" />
    <OverviewCard v-if="longDescription" />

    <HraCard v-if="isHra" />
    <MrosSurvey v-else-if="isMrosSurvey" />
    <EducationBundle v-else-if="isEducationBundle" />
    <HealthPlanFeedbackCard v-else-if="isHealthPlanFeedback" />
    <CompoundHealthActionCard v-else-if="isCompoundHealthAction" />
    <ToDoCard v-else />
  </div>
</template>

<script>
import CompoundHealthActionCard from './CompoundHealthActionCard'
import OverviewCard from './OverviewCard'
import ToDoCard from './ToDoCard'
import EducationBundle from './EducationBundle'
import MrosSurvey from './MrosSurvey'
import healthActionMixin from '@platform-shared/mixins/health-actions/healthActionMixin.js'
import AssignedToCard from './AssignedToCard.vue'
import HraCard from './hra/HraCard.vue'
import HraRiskSummaryCard from './hra/HraRiskSummaryCard.vue'
import HealthPlanFeedbackCard from './health-plan-feedback/HealthPlanFeedbackCard'

export default {
  components: {
    CompoundHealthActionCard,
    OverviewCard,
    ToDoCard,
    EducationBundle,
    MrosSurvey,
    AssignedToCard,
    HraCard,
    HraRiskSummaryCard,
    HealthPlanFeedbackCard,
  },
  mixins: [healthActionMixin],
}
</script>
