<template>
  <div class="flex justify-center">
    <div id="todo-toggle" class="switch" role="tablist" @keydown="changeTabs">
      <button
        id="incomplete-tab-button"
        ref="incompleteTab"
        class="switch-item"
        :class="{ selected: !completeSelected }"
        role="tab"
        aria-controls="incomplete-tabpanel"
        :aria-selected="!completeSelected"
        :tabindex="!completeSelected ? 0 : -1"
        @click="$emit('input', false)"
      >
        {{
          showCounts
            ? $t('health_actions.home.incomplete', [incompleteCount])
            : 'Incomplete'
        }}
      </button>
      <button
        id="complete-tab-button"
        ref="completeTab"
        class="switch-item"
        :class="{ selected: completeSelected }"
        :aria-selected="completeSelected"
        role="tab"
        aria-controls="complete-tabpanel"
        :tabindex="completeSelected ? 0 : -1"
        @click="$emit('input', true)"
      >
        {{
          showCounts
            ? $t('health_actions.home.completed', [completeCount])
            : 'Complete'
        }}
      </button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import _get from 'lodash/get'

export default {
  props: {
    value: Boolean,
    showCounts: {
      type: Boolean,
      default: false,
    },
    action: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters('healthActions', [
      'completedHealthActions',
      'incompleteHealthActions',
      'totalCompleted',
      'totalIncomplete',
    ]),
    incompleteCount() {
      return this.action
        ? _get(this.action, 'toDoMembers.length', 0)
        : this.totalIncomplete
    },
    completeCount() {
      return this.action
        ? _get(this.action, 'completedMembers.length', 0)
        : this.totalCompleted
    },
    completeSelected() {
      return this.value
    },
  },
  methods: {
    changeTabs(e) {
      if (e.keyCode === 39) {
        this.$emit('input', true)
        this.$refs.completeTab.focus()
      } else if (e.keyCode === 37) {
        this.$emit('input', false)
        this.$refs.incompleteTab.focus()
      }
    },
  },
}
</script>
<style scoped>
.switch {
  @apply h-40 mx-auto bg-grey-100 flex rounded-full select-none;
}
.switch-item {
  @apply h-full rounded-full flex items-center justify-center cursor-pointer font-semibold text-grey-600 py-8 px-25;

  &.selected {
    @apply bg-white border border-grey-300;
    color: inherit;
  }
}
</style>
