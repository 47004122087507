<template>
  <div class="p-20">
    <ToDoNotesIconSection />
    <div class="font-bold text-center">
      {{ attestationTitle }}
    </div>
    <Body v-if="!memberAttestedThisPeriod()" class="text-center">
      {{ attestationDescription }}
    </Body>
    <Attestation class="mt-10" />
  </div>
</template>

<script>
import healthActionMixin from '@platform-shared/mixins/health-actions/healthActionMixin.js'
import Attestation from '../MemberAttestation/Attestation'
import ToDoNotesIconSection from './ToDoNotesIconSection.vue'

export default {
  components: {
    ToDoNotesIconSection,
    Attestation,
  },
  mixins: [healthActionMixin],
  data() {
    return {
      showingCompleted: false,
    }
  },

}
</script>