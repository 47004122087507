<template>
  <div>
    <ToDoNotesIconSection />
    <Body class="text-center">
      {{ basicDescription }}
    </Body>
    <div class="px-80 mt-20">
      <PendingBadge v-if="displayPending" :text="$t('health_actions.attestation.pending')" />
      <HMButton v-else rounded="full" :to="{ name: 'profile-update' }">
        {{ $t('edit_account.profile_update') }}
      </HMButton>
    </div>
  </div>
</template>

<script>
import memberActivitySubmissionMixin from '@platform-shared/mixins/health-actions/memberActivitySubmissionMixin.js'
import ToDoNotesIconSection from './ToDoNotesIconSection.vue'
import PendingBadge from './PendingBadge.vue'

  export default {
    components: {
      ToDoNotesIconSection,
      PendingBadge,
    },
    mixins: [memberActivitySubmissionMixin],
    computed: {
        displayPending() {
          return (
            this.hasSubmittedProfileUpdate || this.memberUpdatedProfileThisPeriod()
          )
        },
    },
  }
</script>