<template>
  <div
    v-if="unseenAnnouncements.length"
    class="w-screen pt-20 bg-grey-100"
    style="margin-left: 50%; transform: translateX(-50%);"
  >
    <div
      v-for="announcement in unseenAnnouncements"
      :key="announcement.id"
      class="pb-20"
      role="region"
      :aria-label="$t('common.announcement')"
    >
      <div
        class="flex items-center w-full max-w-2xl px-20 mx-auto md:max-w-3xl lg:max-w-4xl lg:px-0"
      >
        <img
          src="@assets/images/hra/clipboard-pencil.png"
          class="flex-none inline-block"
          width="56"
          role="presentation"
          :alt="$t('common.icons.screen_reader_names.clipboard')"
        />
        <div class="ml-20">
          <p class="text-base font-normal pr-70">
            {{ announcement.legalText }}
            <span
              v-for="(link, i) in announcement.links"
              :key="i"
              :class="{ 'block mt-10': i > 0 }"
            >
              <a
                class="mt-5 text-blue-500 font-open-semibold"
                :href="link.webPath"
                @click.prevent="goToLink(link.webPath, announcement)"
                >{{ link.title }}</a
              >
            </span>
          </p>
        </div>
        <button :aria-label="$t('common.close_btn')">
          <CloseIcon @click="createAgreement(announcement.tag)" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CloseIcon from '@components/common/CloseIcon'
import privacyMixin from '@platform-shared/mixins/agreements/privacy'
import startsWith from 'lodash/startsWith'

export default {
  components: { CloseIcon },
  mixins: [privacyMixin],
  computed: {
    ...mapGetters('member', ['unseenAnnouncements']),
  },
  methods: {
    goToLink(link, announcement) {
      this.createAgreement(announcement.tag)

      if (startsWith(link, 'http')) {
        window.open(link, '_blank')
      } else {
        this.$router.push(link)
      }
    },
  },
}
</script>
