<template>
  <Card
    class="w-full"
    centerContent
    :title="$t('health_actions.details.to_do')"
    icon="Checklist"
    :subtitle="toDoSubtitle"
    accentColor="sky"
  >
    <div
      v-if="!hraIsCompliant || hraStarted"
      class="flex flex-col items-center justify-center w-full p-20"
    >
      <div
        class="flex items-center justify-center rounded-full h-60 w-60 bg-sky-100"
      >
        <Notes class="h-35 text-sky-500" />
      </div>

      <HMButton rounded="full" class="mt-30 w-300" to="/questionnaire/HRA">
        {{ ctaText }}
      </HMButton>

      <div
        v-if="canRefuse"
        class="flex flex-col items-center justify-center mt-20"
      >
        <button
          class="flex items-center cursor-pointer"
          :aria-expanded="additionalActionsExpanded.toString()"
          aria-controls="AdditionalActions"
          @click="additionalActionsExpanded = !additionalActionsExpanded"
        >
          <p class="flex-1 pr-10 text-base underline">
            {{
              $t(
                `hra.details.${
                  additionalActionsExpanded ? 'fewer' : 'more'
                }_actions`
              )
            }}
          </p>

          <Icon
            :iconClass="
              `fas fa-chevron-${additionalActionsExpanded ? 'up' : 'down'}`
            "
            class="mr-auto text-sm"
          />
        </button>

        <div
          v-if="additionalActionsExpanded"
          id="AdditionalActions"
          class="flex flex-col items-center justify-center"
        >
          <a
            class="mt-20 text-blue-500 underline"
            @click="refuseModalOpen = true"
          >
            {{ $t('hra.details.refuse_action') }}
          </a>
        </div>
      </div>

      <div v-if="showHraDisclaimer" class="mt-20">
        <Body class="text-center">
          {{ $t('hra.details.disclosure_statement_text', [ctaText]) }}
          <router-link
            :to="{
              name: 'public-privacy-document',
              params: { title: $t('hra.details.disclosure_statement_link') },
            }"
            class="text-blue-500 underline"
            :title="$t('hra.details.disclosure_statement')"
            target="_blank"
          >
            {{ $t('hra.details.disclosure_statement') }}.
          </router-link>
        </Body>
      </div>
    </div>
    <div v-else class="flex flex-col items-center justify-center w-full p-20">
      <CheckCircle class="text-green-500 h-25" />
      <Body class="font-bold">{{ title }}</Body>
      <Body class="text-sm">
        {{ $t('health_actions.details.completed_on', [hraLastTaken]) }}
      </Body>
    </div>

    <Modal
      :active="refuseModalOpen"
      closeOnBackDrop
      class="w-100"
      @close="closeModal"
    >
      <RefuseModal @close="closeModal" />
    </Modal>
  </Card>
</template>

<script>
import { Notes, CheckCircle } from '@assets/svgs/index'
import { Card } from '@components/common/card/index'
import Modal from '@components/common/Modal'
import RefuseModal from './RefuseModal'
import healthActionMixin from '@platform-shared/mixins/health-actions/healthActionMixin.js'

export default {
  components: {
    Card,
    CheckCircle,
    Modal,
    Notes,
    RefuseModal,
  },
  mixins: [healthActionMixin],
  computed: {
    ctaText() {
      if (this.hraIsCompliant && this.hraStarted) {
        return this.$t('hra.details.continue_retake')
      } else if (this.hraStarted) {
        return this.$t('hra.details.continue')
      } else {
        return this.$t('hra.details.start')
      }
    },
  },
  data() {
    return {
      additionalActionsExpanded: false,
      refuseModalOpen: false,
    }
  },
  methods: {
    closeModal() {
      this.refuseModalOpen = false
    },
  },
}
</script>

<style></style>
