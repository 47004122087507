<template>
  <MemberActivitySubmissionWrapper :handleComplete="handleComplete" :handleCancel="goToHealthActionDetails">
    <ArticleDetail />
  </MemberActivitySubmissionWrapper>
</template>

<script>
import ArticleDetail from '../../../learn/ArticleDetail.vue'
import MemberActivitySubmissionWrapper from './MemberActivitySubmissionWrapper'
import memberActivitySubmissionMixin from '@platform-shared/mixins/health-actions/memberActivitySubmissionMixin.js'

export default {
  components: { MemberActivitySubmissionWrapper, ArticleDetail },
  mixins: [memberActivitySubmissionMixin],
  methods: {
    goToHealthActionDetails() {
      this.$router.push({ name: 'health-actions-details', params: { id: this.$route.params.id } })
    },
    handleComplete() {
      this.handleLearnAndEarnComplete().then(() => this.goToHealthActionDetails())
    },
  }
}
</script>

  