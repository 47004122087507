<template>
  <Survey :survey="survey" />
</template>

<script>
import 'survey-core/defaultV2.min.css'
import { defaultSurveyTheme } from '@platform-shared/styles/surveyTheme'
import { Model } from 'survey-core'
import { Survey } from 'survey-vue-ui'

export default {
  components: {
    Survey,
  },
  props: {
    config: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  data() {
    const survey = new Model(this.config.config)
    survey.applyTheme(defaultSurveyTheme)
    survey.locale = this.config.languagePref
    survey.onComplete.add(this.onSubmitSurvey)
    return {
      survey,
    }
  },
  methods: {
    onSubmitSurvey(sender) {
      this.$emit('onSubmit', sender.data)
    },
  },
}
</script>
<style>
input[type='button'] {
  border-radius: 9999px;
}
</style>
