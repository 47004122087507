<template>
  <div class="w-full max-w-3xl py-20 mb-200">
    <Back
      route="/health-actions/hra/summary"
      title="questionnaire.hra_summary_risk.back_to_summary"
      class="my-20"
    />
    <Spinner v-if="isLoadingMyHealthRisk" />
    <h5 v-else-if="!risk" class="text-center">
      {{ $t('questionnaire.hra_summary_risk.no_results') }}
    </h5>
    <div v-else>
      <h2>
        {{ risk.name }}
      </h2>
      <div
        v-if="!isLowRisk"
        class="flex items-center justify-center rounded-lg bg-grey-50 py-30 mt-30"
      >
        <Icon iconClass="fas fa-circle" class="mr-12 text-xs text-tan-500" />
        <h5>{{ risk.level }}</h5>
      </div>
      <h5 class="mb-8 text-center mt-30">
        {{ $t('questionnaire.hra_summary_risk.description') }}
      </h5>
      <Markdown :source="risk.description" />
      <div v-if="risk.riskDescription">
        <div class="h-2 mt-20 bg-grey-100" />
        <h5 class="mt-20 mb-16 text-center">
          {{ $t('questionnaire.hra_summary_risk.next_steps') }}
        </h5>
        <Markdown :source="risk.riskDescription" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import _find from 'lodash/find'
import Spinner from '@components/common/Spinner'
import Markdown from '@components/common/Markdown'
import { FEATURES } from '@platform-shared/constants'

export default {
  components: { Spinner, Markdown },
  computed: {
    ...mapGetters('healthProfile', ['isLoadingMyHealthRisk', 'healthSummary']),
    ...mapGetters('client', ['hasFeature']),
    risk() {
      return _find(this.healthSummary, { route: this.$route.params.risk })
    },
    isLowRisk() {
      return this.risk.level !== 'Needs Attention'
    },
  },
  created() {
    this.hasFeature(FEATURES.HRA_RISK) &&
      !this.healthSummary.length &&
      this.getMyHealthRisks()
  },
  methods: {
    ...mapActions('healthProfile', ['getMyHealthRisks']),
  },
}
</script>

<style></style>
