<template>
  <div
    class="rounded-md bg-white p-20 flex flex-col justify-center items-center w-screen"
    style="max-width: 700px"
  >
    <button
      class="flex w-full justify-end"
      :aria-label="$t('common.close_btn')"
      @click="done"
    >
      <CloseIcon />
    </button>
    <div class="px-100">
      <component
        :is="currentScreenComponent"
        :isFinalScreen="currentScreenIdx === screens.length - 1"
        @next="nextScreen"
        @done="done"
        @goToFte="goToFteQuestionnaire"
        @goToRewards="goToRewards"
      />
    </div>
    <div class="mt-30 flex">
      <button
        v-for="(dot, idx) in screens"
        :key="idx"
        class="h-10 w-10 mx-5 rounded-full cursor-pointer"
        :class="{
          'bg-grey-300': idx !== currentScreenIdx,
          'bg-blue-500': idx === currentScreenIdx,
        }"
        :aria-label="$t('welcome_modal.go_to', [idx + 1])"
        :aria-current="(idx === currentScreenIdx).toString()"
        @click="currentScreenIdx = idx"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import WelcomeScreen from './WelcomeScreen'
import WhatsIncludedScreen from './WhatsIncludedScreen'
import FinalPromptScreen from './FinalPromptScreen'
import CloseIcon from '@components/common/CloseIcon'
import _get from 'lodash/get'
import { FEATURES, AGREEMENT_TAG_TYPES } from '@platform-shared/constants'
import getTime from 'date-fns/getTime'
import rewardsCurrency from '@platform-shared/mixins/rewardsCurrency'

export default {
  components: {
    CloseIcon,
  },
  mixins: [rewardsCurrency],
  data() {
    return {
      currentScreenIdx: 0,
    }
  },
  computed: {
    ...mapGetters('questionnaire', ['startUpQuestionnaire']),
    ...mapGetters('client', ['hasFeature']),
    screens() {
      const hasFte = this.hasFeature(FEATURES.FTE)
      return [
        WelcomeScreen,
        WhatsIncludedScreen,
        ...(hasFte || this.usesPoints ? [FinalPromptScreen] : []), // will need to revisit this for points programs that DO have the FTE enabled
      ]
    },
    currentScreenComponent() {
      return _get(this.screens, this.currentScreenIdx, WelcomeScreen)
    },
  },
  methods: {
    ...mapActions('member', ['createAgreement']),
    goToFteQuestionnaire() {
      this.done().then(() => {
        if (this.startUpQuestionnaire) {
          localStorage.setItem('fteViewTime', getTime(new Date()))
          return this.$router.push(
            `/questionnaire/${this.startUpQuestionnaire.type}`
          )
        }
      })
    },
    goToRewards() {
      this.done().then(() => {
        return this.$router.push({ name: 'rewards', hash: '#how-it-works' })
      })
    },
    done() {
      return this.createAgreement(AGREEMENT_TAG_TYPES.PHA_WELCOME)
    },
    nextScreen() {
      this.currentScreenIdx++
    },
  },
}
</script>

<style></style>
