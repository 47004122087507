<template>
  <Card class="mb-30">
    <div class="p-20 text-center">
      <h2 class="text-lg font-bold">{{ $t('hra.details.summary_title') }}</h2>
      <p v-if="hraLastTaken">
        {{ $t('hra.details.last_taken', [hraLastTaken]) }}
      </p>
    </div>
    <CardDivider />
    <div class="flex flex-col items-center p-20">
      <h3
        class="px-12 py-4 font-bold rounded-sm text-base"
        :class="{
          [`bg-${riskColor}-100 border-${riskColor}-500 border`]: riskColor,
        }"
      >
        {{ hraOverallRisk.level }}
      </h3>
      <p class="mt-20">{{ hraOverallRisk.description }}</p>
      <HMButton
        v-if="hasHraRisk"
        rounded="full"
        class="mt-20 w-300"
        to="/health-actions/hra/summary"
      >
        {{ $t('hra.details.learn_more') }}
      </HMButton>
    </div>
  </Card>
</template>

<script>
import { Card, CardDivider } from '@components/common/card/index'
import healthActionMixin from '@platform-shared/mixins/health-actions/healthActionMixin.js'

export default {
  components: { Card, CardDivider },
  mixins: [healthActionMixin],
}
</script>

<style></style>
