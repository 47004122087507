<template>
  <div class="text-center">
    <div v-if="!showDependents">
      <div
        v-if="usesRewards"
        class="inline-block px-12 py-3 mb-12 font-bold text-center text-green-600 bg-lime-100 rounded-sm"
      >
        {{ badgeText }}
      </div>
      <div v-if="isComplete" class="text-center">
        <p class="font-bold">{{ $t('common.completed') }}</p>
        <p>{{ lastCompletion }}</p>
      </div>
      <p v-else class="font-bold">{{ percentageText }}</p>
    </div>
    <div v-else>
      <div
        class="inline-block px-12 py-3 mb-12 font-bold text-center text-green-600 bg-lime-100 rounded-sm"
      >
        {{ dependentBadgeText }}
      </div>
      <div v-if="totalEarnedText" class="text-center">
        <p class="font-bold">{{ totalEarnedText }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import healthActionMixin from '@platform-shared/mixins/health-actions/healthActionMixin.js'
import rewardsCurrency from '@platform-shared/mixins/rewardsCurrency'
import { FEATURES } from '@platform-shared/constants'

export default {
  mixins: [healthActionMixin, rewardsCurrency],
  computed: {
    usesRewards() {
      return this.hasFeature(FEATURES.REWARDS)
    },
    badgeText() {
      if (this.isRewardedThisCompliancePeriod && this.incentiveCatalogCd) {
        return this.$t('health_actions.home.earned_direct_incentive', [
          this.incentiveAmount,
        ])
      } else if (
        !this.isRewardedThisCompliancePeriod &&
        this.incentiveCatalogCd
      ) {
        return this.$t('health_actions.home.direct_incentive', [
          this.incentiveAmount,
        ])
      } else if (this.isRewardedThisCompliancePeriod) {
        return this.$t(`health_actions.details.earned.${this.currencyType}`, [
          this.totalRewardsEarned,
        ])
      } else if (
        this.hasComplexChildrenWithRewards &&
        this.usesPoints &&
        !this.isComplete
      ) {
        return this.$t('health_actions.home.earn_up_to.points', [
          this.incentiveAmount,
        ])
      } else if (
        this.incentiveAmount > 0 &&
        !this.isComplete &&
        this.showEarning
      ) {
        return this.$t(`health_actions.details.earn.${this.currencyType}`, [
          this.totalRewardsPossible,
        ])
      } else {
        return this.$t('health_actions.details.no_reward')
      }
    },
    percentageText() {
      return this.$t('health_actions.details.pct_complete', [
        this.percentageComplete,
      ])
    },

    dependentBadgeText() {
      if (!this.incentiveAmount) {
        return this.$t('health_actions.details.no_reward')
      } else if (this.toDoMembers.length === 0) {
        return this.$t(`health_actions.details.earned.${this.currencyType}`, [
          this.totalRewardsEarned,
        ])
      } else if (this.toDoMembers.length === 1) {
        return this.$t(`health_actions.details.earn.${this.currencyType}`, [
          this.incentiveAmount,
        ])
      } else {
        return this.$t(`health_actions.details.dep_earn.${this.currencyType}`, [
          this.incentiveAmount,
        ])
      }
    },
    totalEarnedText() {
      return this.incentiveAmount
        ? this.$t(`health_actions.details.earned_of.${this.currencyType}`, [
            this.totalRewardsEarned,
            this.totalRewardsPossible,
          ])
        : ''
    },
  },
}
</script>

<style></style>
