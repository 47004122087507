<template>
  <div class="flex items-center justify-center mb-20 mx-auto rounded-full h-60 w-60 bg-sky-100">
    <Notes class="h-35 w-35 text-sky-500" />
  </div>
</template>

<script>
import { Notes } from '@assets/svgs/index'

export default {
  components: {
    Notes,
  },
}
</script>