<template>
  <RadialProgressBar
    class="transform rotate-180"
    :diameter="130"
    :strokeWidth="6"
    :innerStrokeWidth="6"
    startColor="#FF8A00"
    stopColor="#FFD057"
    innerStrokeColor="#E6E6E6"
    :completed-steps="percentageComplete"
    :total-steps="100"
  >
    <div
      class="flex items-center justify-center transform rotate-180 rounded-full h-80 w-80"
      :class="`bg-sky-100`"
    >
      <Coins v-if="showCoins" class="h-40 text-sky-500" />
      <GiftBox v-else class="h-40 text-sky-500" />
    </div>
  </RadialProgressBar>
</template>

<script>
import { GiftBox, Coins } from '@assets/svgs/index'
import healthActionMixin from '@platform-shared/mixins/health-actions/healthActionMixin.js'
import RadialProgressBar from 'vue-radial-progress'

export default {
  components: { GiftBox, Coins, RadialProgressBar },
  mixins: [healthActionMixin],
  computed: {
    showCoins() {
      if (this.showDependents) {
        return this.toDoMembers.length === 0
      } else {
        return this.isRewardedThisCompliancePeriod
      }
    },
  },
}
</script>

<style></style>
