var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex items-center mb-14"},[_c('h5',{staticClass:"flex-1"},[_vm._v("Trackers")]),_c('router-link',{staticClass:"flex items-center text-sm text-blue-500 underline font-semibold py-5 rounded-full",attrs:{"to":{ name: 'tracking' }}},[_c('span',[_vm._v(_vm._s(_vm.$t('tracking.home.tracker_settings')))])])],1),_c('div',{staticClass:"flex",attrs:{"role":"region","aria-label":_vm.$t('tracking.slideshow')}},[_c('button',{staticClass:"w-40 h-80 mr-30 flex flex-0 justify-center items-center outline-none focus:outline-none",on:{"click":function($event){return _vm.$refs.trackerCarousel.previous()}}},[_c('i',{staticClass:"icon-caret-left text-2xl"})]),_c('div',{staticClass:"flex-1 h-80"},[_c('vueper-slides',{key:_vm.sliderKey,ref:"trackerCarousel",staticClass:"no-shadow",attrs:{"bullets":false,"arrows":false,"visible-slides":4,"gap":1,"breakpoints":_vm.sliderBreakpoints,"fixed-height":"80px","touchable":false,"infinite":true,"slide-multiple":true},on:{"ready":_vm.addSliderBreakpoints}},_vm._l((_vm.trackers),function(tracker){return _c('vueper-slide',{key:tracker.type,scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('router-link',{staticClass:"rounded-lg border border-grey-300 h-80 flex flex-row px-10 items-center",attrs:{"to":{
                name: 'tracking-detail',
                params: {
                  name: tracker.type,
                  backTo: '/',
                },
              }}},[_c('div',{staticClass:"rounded-full w-35 h-35 mr-10 flex items-center justify-center bg-plum-100"},[_c('Icon',{staticClass:"text-lg text-plum-500",attrs:{"iconClass":_vm.getIcon(tracker.type).type}})],1),_c('div',{staticClass:"flex flex-col"},[_c('span',{staticClass:"text-lg font-bold"},[_vm._v(_vm._s(tracker.todaysDisplayValue)+" "+_vm._s(_vm.$t(`tracking.lingo.${tracker.type}.units`)))]),_c('span',{staticClass:"text-sm"},[_vm._v(_vm._s(_vm.$t(`tracking.lingo.${tracker.type}.display`)))])])])]},proxy:true}],null,true)})}),1)],1),_c('button',{staticClass:"w-40 h-80 ml-30 flex flex-0 justify-center items-center outline-none focus:outline-none",on:{"click":function($event){return _vm.$refs.trackerCarousel.next()}}},[_c('i',{staticClass:"icon-caret-right text-2xl"})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }