<template>
  <div class="flex flex-col items-center max-w-2xl px-40 py-20">
    <H1 class="self-start">
      {{ $t('hra.refuse_modal.title') }}
    </H1>

    <p class="mt-20">
      {{ $t('hra.refuse_modal.description') }}
    </p>

    <FormError v-if="error" />

    <HMButton
      class="w-full mt-25"
      rounded="full"
      color="red"
      :aria-label="$t('hra.details.refuse_action')"
      :disabled="isRefusingInProgress"
      @click="onRefusalConfirmed"
    >
      {{ $t('hra.refuse_modal.refuse_button') }}
    </HMButton>

    <a class="self-center mt-20 underline" @click="$emit('close')">
      {{ $t('hra.refuse_modal.cancel_button') }}
    </a>
  </div>
</template>

<script>
import { FormError } from '@components/common/forms'
import { mapActions } from 'vuex'

export default {
  components: { FormError },
  data() {
    return {
      error: false,
      isRefusingInProgress: false,
    }
  },
  methods: {
    ...mapActions('questionnaire', ['refuse']),

    onRefusalConfirmed() {
      this.error = false
      this.isRefusingInProgress = true

      this.refuse({ questionnaireType: 'HRA' })
        .then(() => {
          this.isRefusingInProgress = false
          this.$router.push('/')
        })
        .catch(() => {
          this.isRefusingInProgress = false
          this.error = true
        })
    },
  },
}
</script>
