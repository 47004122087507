<template>
  <div class="flex flex-col items-center text-center">
    <h5 class="mt-8">
      {{ $t(`welcome_modal.${currencyType}.fte_prompt.title`) }}
    </h5>
    <img class="w-100 mt-25" src="@assets/images/welcome/things.png" />
    <p class="font-bold mt-30">
      {{ $t(`welcome_modal.${currencyType}.fte_prompt.subtitle`) }}
    </p>
    <div>
      <div v-if="usesPoints">
        <HMButton
          class="mt-50 w-350"
          rounded="full"
          :disabled="isLoadingAgreements"
          @click="$emit('done')"
        >
          {{ $t(`welcome_modal.${currencyType}.fte_prompt.button_1`) }}
        </HMButton>
        <HMButton
          class="mt-20 w-350"
          color="white"
          outlined
          rounded="full"
          @click="$emit('goToRewards')"
        >
          {{ $t(`welcome_modal.${currencyType}.fte_prompt.button_2`) }}
        </HMButton>
      </div>

      <!-- will need to revisit this for points programs that DO have the FTE enabled -->
      <div v-else>
        <HMButton
          v-if="hasFteQuestionnaire"
          class="mt-50 w-350"
          rounded="full"
          :disabled="isLoadingAgreements"
          @click="$emit('goToFte')"
        >
          {{ $t(`welcome_modal.${currencyType}.fte_prompt.button_1`) }}
        </HMButton>
        <HMButton
          v-if="!usesPoints"
          class="mt-20 w-350"
          color="white"
          outlined
          rounded="full"
          @click="$emit('done')"
        >
          {{ $t(`welcome_modal.${currencyType}.fte_prompt.button_2`) }}
        </HMButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import rewardsCurrency from '@platform-shared/mixins/rewardsCurrency'
import { FEATURES } from '@platform-shared/constants'

export default {
  mixins: [rewardsCurrency],
  computed: {
    ...mapGetters('member', ['isLoadingAgreements']),
    ...mapGetters('client', ['hasFeature']),
    hasFteQuestionnaire() {
      return this.hasFeature(FEATURES.FTE)
    },
  },
  methods: {
    confirm() {
      if (this.hasFteQuestionnaire) {
        return this.$emit('goToFte')
      } else {
        return this.$emit('done')
      }
    },
  },
}
</script>

<style></style>
