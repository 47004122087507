<template>
  <Card class="w-full h-full md:w-250 mb-30 md:mr-30">
    <div class="flex flex-col items-center justify-center p-30">
      <ProgressIcon class="mb-30" />
      <EarnBadge />
    </div>
    <router-link
      v-for="action in links"
      :key="action.title"
      :to="action.webRoute"
    >
      <CardListItem :title="action.title" :icon="action.icon" />
    </router-link>
  </Card>
</template>

<script>
import { Card, CardListItem } from '@components/common/card/index'
import ProgressIcon from './ProgressIcon'
import EarnBadge from './EarnBadge'
import healthActionMixin from '@platform-shared/mixins/health-actions/healthActionMixin.js'

export default {
  components: { Card, CardListItem, ProgressIcon, EarnBadge },
  mixins: [healthActionMixin],
}
</script>

<style></style>
