<template>
  <Card class="w-full" centerContent>
    <CardHeader
      :title="$t('health_actions.details.to_do')"
      icon="Checklist"
      accentColor="sky"
    >
      <div v-if="showDependents">
        <ToDoToggle
          v-model="showingCompleted"
          showCounts
          :action="healthAction"
        />
      </div>
      <div v-else class="flex justify-end font-bold">{{ toDoSubtitle }}</div>
    </CardHeader>
    <div :class="{ 'w-full': showDependents }">
      <div v-if="showDependents && showingCompleted">
        <CardListItem
          v-for="member in completedMembers"
          :key="member.id"
          :title="$t(member.name)"
          :subtitle="
            $t('health_actions.details.completed_on', [lastCompletion])
          "
        >
          <div
            v-if="incentiveAmount"
            class="inline-block px-8 font-semibold text-green-600 bg-lime-100 rounded-sm"
          >
            {{
              $t(`health_actions.details.earned.${currencyType}`, [
                incentiveAmount,
              ])
            }}
          </div>
        </CardListItem>
      </div>
      <div v-else-if="showDependents && !showingCompleted" class="w-full">
        <CardListItem
          v-for="member in toDoMembers"
          :key="member.id"
          :title="$t(member.name)"
          :subtitle="
            incentiveAmount
              ? $t(`health_actions.details.earn.${currencyType}`, [
                  incentiveAmount,
                ])
              : ''
          "
          subtitleColor="text-green-600"
          subtitleBackGroundColor="bg-lime-100"
          padding="px-6"
        >
          <Attestation :memberId="member.memberId" />
        </CardListItem>
      </div>
      <div v-else-if="isComplete" class="flex flex-col items-center p-20">
        <CheckCircle class="w-24 h-24 mb-6 text-green-500" />
        <Body class="font-bold">
          {{ $t('health_actions.details.completed_on', [adjustedTitle]) }}
        </Body>
        <Body class="text-sm">
          {{ $t('health_actions.details.completed_on', [lastCompletion]) }}
        </Body>
      </div>
      <div v-else class="p-20">
        <AttestationToDoDetails v-if="supportsMemberAttestation" />
        <LearnAndEarnToDoDetails v-else-if="isLearnAndEarn" />
        <ProfileUpdateToDoDetails v-else-if="isProfileUpdate" />
        <BasicToDoDetails v-else />
      </div>
    </div>
  </Card>
</template>

<script>
import { Card, CardHeader } from '@components/common/card/index'
import { Notes, CheckCircle } from '@assets/svgs/index'
import healthActionMixin from '@platform-shared/mixins/health-actions/healthActionMixin.js'
import rewardsCurrency from '@platform-shared/mixins/rewardsCurrency'
import ToDoToggle from '../ToDoToggle'
import CardListItem from '../../../common/card/CardListItem'
import Attestation from '../MemberAttestation/Attestation'
import LearnAndEarnToDoDetails from './LearnAndEarnToDoDetails.vue'
import ProfileUpdateToDoDetails from './ProfileUpdateToDoDetails.vue'
import BasicToDoDetails from './BasicToDoDetails.vue'
import AttestationToDoDetails from './AttestationToDoDetails.vue'

export default {
  components: {
    Card,
    CardHeader,
    Notes,
    CheckCircle,
    ToDoToggle,
    CardListItem,
    Attestation,
    BasicToDoDetails,
    AttestationToDoDetails,
    LearnAndEarnToDoDetails,
    ProfileUpdateToDoDetails
  },
  mixins: [healthActionMixin, rewardsCurrency],
  data() {
    return {
      showingCompleted: false,
    }
  },

}
</script>

<style></style>
