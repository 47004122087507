<template>
  <Card
    :title="$t('health_actions.details.to_do')"
    icon="Checklist"
    accentColor="sky"
    :subtitle="toDoSubtitle"
  >
    <Spinner v-if="isLoading" class="mt-16" />
    <div
      v-for="q in mrosQuestionnaires"
      :key="q.type"
      class="flex items-center justify-center p-20 border-t first:border-t-0"
    >
      <div class="text-center">
        <div v-if="isQuestionnaireCompleted(q.type)">
          <Icon
            iconClass="fas fa-check-circle"
            class="text-2xl text-green-500"
          />
          <span class="block font-bold" v-text="q.name" />
          <span class="block text-sm"
            >Completed {{ questionnaireCompletedDate(q.type) | FD }}</span
          >
        </div>
        <div
          v-else-if="isQuestionnaireAvailable(q.type)"
          class="flex flex-col items-center"
        >
          <div
            class="flex items-center justify-center rounded-full w-60 h-60"
            :class="`bg-sky-100`"
          >
            <Notes class="h-35" :class="`text-sky-500`" />
          </div>
          <HMButton
            rounded="full"
            class="px-40 py-12 mt-10 w-300"
            :to="{ name: 'questionnaire', params: { type: q.type } }"
          >
            Start {{ q.name }} Survey
          </HMButton>
        </div>
        <div v-else>
          <CalendarClock class="inline-block text-black h-25" />
          <span class="block font-bold" v-text="q.name" />
          <span class="block text-sm">Available {{ q.availableOn | FD }}</span>
        </div>
      </div>
    </div>
  </Card>
</template>

<script>
import { Card } from '@components/common/card/index'
import { Notes, CalendarClock } from '@assets/svgs/index'
import Spinner from '../../../common/Spinner'
import { mapGetters } from 'vuex'
import healthActionMixin from '@platform-shared/mixins/health-actions/healthActionMixin.js'

export default {
  components: { Spinner, Card, Notes, CalendarClock },
  mixins: [healthActionMixin],
  computed: {
    ...mapGetters('questionnaire', ['isLoading']),
  },
}
</script>
