<template>
  <div
    class="w-full max-w-3xl max-md:w-screen h-full bg-white overflow-auto border-l"
  >
    <div class="px-30 py-20 border-b border-grey-200">
      <div class="flex items-center">
        <h3 class="flex-1">
          {{ $t('questionnaire.hra_compare.title') }}
        </h3>
        <CloseIcon @click="close" />
      </div>
      <p>{{ $t('questionnaire.hra_compare.questionnaire_name') }}</p>
    </div>
    <div class="p-30">
      <div v-for="question in questions" :key="question.question" class="mb-30">
        <p class="font-semibold">{{ question.question }}</p>
        <div
          v-for="(year, yearIdx) in question.yearlyAnswers"
          :key="year.year"
          class="mt-12 px-20 py-16 flex items-center"
          :class="{ 'bg-grey-100': yearIdx % 2 === 0 }"
        >
          <p class="flex-1">
            {{ $t('questionnaire.hra_compare.year_answer', [year.year]) }}
          </p>
          <p class="flex-1">{{ year.answers.join(', ') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from '@components/common/CloseIcon'
export default {
  components: { CloseIcon },
  props: {
    questionnaire: {
      type: Object,
      required: false,
    },
    questions: {
      type: Array,
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit('closeDrawer')
    },
  },
}
</script>

<style></style>
