<template>
  <Card :title="$t('health_actions.details.history')">
    <CardDivider />
    <div class="p-20">
      <div v-if="allCompletions.length > 0">
        <p class="flex justify-between pb-10 mb-10 border-b-2">
          {{ $t('health_actions.history.date_completed') }}
        </p>
        <p
          v-for="{
            name,
            memberId,
            childHealthActionName,
            formattedDate,
          } in allCompletions"
          :key="`${memberId}-${childHealthActionName}-${formattedDate}`"
          class="my-10"
        >
          <span v-if="showDependents" class="font-bold">{{ $t(name) }} - </span>
          <span>{{ formattedDate }}</span>
          <span v-if="isComplex"> - {{ childHealthActionName }} </span>
        </p>
      </div>
      <H3 v-else>
        {{ $t('health_actions.history.no_history') }}
      </H3>
    </div>
  </Card>
</template>
<script>
import { mapGetters } from 'vuex'
import _find from 'lodash/find'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import flow from 'lodash/fp/flow'
import getOr from 'lodash/fp/getOr'
import map from 'lodash/fp/map'
import compact from 'lodash/compact'
import flatten from 'lodash/flatten'
import orderBy from 'lodash/fp/orderBy'
import { HEALTH_ACTION_TYPES } from '@platform-shared/constants'
import { Card, CardDivider } from '@components/common/card/index'

export default {
  components: { Card, CardDivider },
  computed: {
    ...mapGetters('healthActions', [
      'allHealthActions',
      'healthActionsLoading',
    ]),
    ...mapGetters('member', ['memberCoverageInfo', 'showDependents']),
    action() {
      return _find(this.allHealthActions, {
        id: parseInt(this.$route.params.id),
      })
    },
    isComplex() {
      return this.action.healthActionTypeCd === HEALTH_ACTION_TYPES.COMPLEX
    },
    allCompletions() {
      return flow(
        getOr([], 'memberHealthActions'),
        map(({ memberId, name, completions }) =>
          map(({ completionDate, childHealthActionName }) => ({
            name,
            memberId,
            formattedDate: this.formatDate(completionDate),
            completionDate,
            childHealthActionName,
          }))(completions)
        ),
        compact,
        flatten,
        orderBy('completionDate', 'desc')
      )(this.action)
    },
  },
  methods: {
    formatDate(date) {
      return date ? format(parseISO(date), 'MMMM dd, yyyy') : ''
    },
  },
}
</script>
