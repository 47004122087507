<template>
  <div class="w-full max-w-3xl py-20 mb-200">
    <Back
      route="/health-actions/hra"
      title="questionnaire.hra_summary.back_to_hra"
      class="my-20"
    />
    <Spinner v-if="isLoadingMyHealthRisk" />
    <p v-else-if="!healthSummary.length">
      {{ $t('questionnaire.hra_summary.no_results') }}
    </p>
    <div v-else>
      <h1 class="h2">
        {{ $t('questionnaire.hra_summary.title') }}
      </h1>
      <router-link
        v-for="risk in healthSummary"
        :key="risk.name"
        class="flex border-b py-12 cursor-pointer"
        :to="`/hra/summary/${risk.route}`"
      >
        <div class="flex flex-1">
          <p class="mr-12">{{ risk.name }}</p>
          <div
            v-if="risk.color"
            class="h-25 px-10 rounded-sm text-center flex items-center"
            :class="risk.color === 'danger' ? 'bg-red-100' : 'bg-tan-100'"
          >
            <p class="text-xs font-semibold">{{ risk.level }}</p>
          </div>
        </div>
        <Icon iconClass="fas fa-chevron-right" />
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Spinner from '@components/common/Spinner'
import { FEATURES } from '@platform-shared/constants'

export default {
  components: { Spinner },
  computed: {
    ...mapGetters('healthProfile', ['isLoadingMyHealthRisk', 'healthSummary']),
    ...mapGetters('client', ['hasFeature']),
  },
  created() {
    this.hasFeature(FEATURES.HRA_RISK) &&
      !this.healthSummary.length &&
      this.getMyHealthRisks()
  },
  methods: {
    ...mapActions('healthProfile', ['getMyHealthRisks']),
  },
}
</script>

<style></style>
