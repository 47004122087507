<template>
  <div class="flex flex-col items-center text-center">
    <h5 class="mt-8">
      {{ $t(`welcome_modal.${prepareLanguagePath}.welcome.title`) }}
    </h5>
    <img class="w-150 mt-25" src="@assets/images/welcome/pha.png" />
    <p class="mt-30 font-bold">
      {{ $t(`welcome_modal.${prepareLanguagePath}.welcome.subtitle`) }}
    </p>
    <p class="mt-30">
      {{ $t(`welcome_modal.${prepareLanguagePath}.welcome.body`) }}
    </p>
    <HMButton class="mt-50 w-350" rounded="full" @click="$emit('next')">
      {{ $t('common.next_btn') }}
    </HMButton>
  </div>
</template>

<script>
import rewardsCurrency from '@platform-shared/mixins/rewardsCurrency'

export default {
  mixins: [rewardsCurrency],
}
</script>

<style></style>
