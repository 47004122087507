<template>
  <Card class="w-full">
    <CardHeader
      :title="$t('health_actions.details.to_do')"
      icon="Checklist"
      accentColor="sky"
    >
      <div v-if="showDependents">
        <ToDoToggle
          v-model="showingCompleted"
          showCounts
          :action="healthAction"
        />
      </div>
      <div v-else class="flex justify-end font-bold">{{ toDoSubtitle }}</div>
    </CardHeader>
    <div class="divide-y">
      <div
        v-for="componentHealthAction in healthAction.compoundHealthActions"
        :key="componentHealthAction.id"
      >
        <div
          v-if="isComponentHealthActionCompleted(componentHealthAction)"
          class="flex flex-col items-center p-20"
        >
          <CheckCircle class="w-24 h-24 mb-6 text-green-500" />
          <div
            v-if="usesRewards && componentHealthAction.incentiveAmount > 0"
            class="inline-block px-12 py-3 mb-12 font-bold text-center text-white bg-green-500 rounded-sm"
          >
            {{
              composeComponentHealthActionRewardBadgeText(componentHealthAction)
            }}
          </div>
          <Body class="font-bold">
            {{ $t('health_actions.details.completed_on', [adjustedTitle]) }}
          </Body>
          <Body class="text-sm">
            {{
              $t('health_actions.details.completed_on', [
                lastCompoundCompletion(componentHealthAction),
              ])
            }}
          </Body>
        </div>
        <div v-else class="flex flex-col items-center p-20">
          <div
            class="flex items-center justify-center mb-20 rounded-full h-60 w-60 bg-sky-100"
          >
            <Notes class="h-35 w-35 text-sky-500" />
          </div>
          <div
            v-if="usesRewards && componentHealthAction.incentiveAmount > 0"
            class="inline-block px-12 py-3 mb-12 font-bold text-center text-white bg-blue-500 rounded-sm"
          >
            {{
              composeComponentHealthActionRewardBadgeText(componentHealthAction)
            }}
          </div>
          <Body class="font-bold">
            {{ componentHealthAction.title }}
          </Body>
          <div v-if="supportsMemberAttestation" class="font-bold">
            {{ attestationTitle }}
          </div>
          <Markdown
            class="text-center"
            :source="componentHealthAction.longDescription"
          />
          <Attestation
            class="mt-10"
            :overrideHealthActionId="componentHealthAction.id"
          />
        </div>
      </div>
    </div>
  </Card>
</template>

<script>
import { mapGetters } from 'vuex'
import isEmpty from 'lodash/fp/isEmpty'
import { Card, CardHeader } from '@components/common/card/index'
import Markdown from '@components/common/Markdown'
import { FEATURES } from '@platform-shared/constants'
import healthActionMixin from '@platform-shared/mixins/health-actions/healthActionMixin.js'
import rewardsCurrency from '@platform-shared/mixins/rewardsCurrency'
import Attestation from '../MemberAttestation/Attestation'
import { Notes, CheckCircle } from '@assets/svgs/index'

export default {
  components: {
    Card,
    CardHeader,
    Markdown,
    Notes,
    CheckCircle,
    Attestation,
  },
  mixins: [healthActionMixin, rewardsCurrency],
  computed: {
    ...mapGetters('rewards', ['earnedAmountByHealthActionCd']),
    usesRewards() {
      return this.hasFeature(FEATURES.REWARDS)
    },
  },
  methods: {
    isComponentHealthActionCompleted({ toDoMembers }) {
      return isEmpty(toDoMembers)
    },
    composeComponentHealthActionRewardBadgeText(componentHealthAction) {
      const isComplete = this.isComponentHealthActionCompleted(
        componentHealthAction
      )
      const earnedAmount = this.earnedAmountByHealthActionCd(
        componentHealthAction.code
      )

      if (isComplete && earnedAmount) {
        return this.$t(`health_actions.details.earned.${this.currencyType}`, [
          earnedAmount,
        ])
      } else if (isComplete && !earnedAmount) {
        return this.$t('common.completed')
      } else if (componentHealthAction.healthActionTypeCd === 'X') {
        return this.$t('health_actions.home.earn_up_to.points', [
          componentHealthAction.incentiveAmount,
        ])
      }

      return this.$t(`health_actions.details.earn.${this.currencyType}`, [
        componentHealthAction.incentiveAmount,
      ])
    },
  },
}
</script>
